import React, { useState, useEffect } from 'react';
import './Snake.css';

const ROWS = 20;
const COLS = 20;

const initialSnake = [
    { row: 10, col: 2 },
    { row: 10, col: 1 },
    { row: 10, col: 0 },
];

function Snake() {
    const [snake, setSnake] = useState(initialSnake);
    const [direction, setDirection] = useState('right');
    const [food, setFood] = useState(randomFood());
    const [gameOver, setGameOver] = useState(false);
    const [gameStatus, setGameStatus] = useState(false);

    function reset() {
        setGameOver(false);
        setGameStatus(false);
        setSnake(initialSnake);
    }

    useEffect(() => {
        const interval = setInterval(moveSnake, 100);
        return () => clearInterval(interval);
    });

    function moveSnake() {
        if (!gameStatus || gameOver)
            return;
        const head = getNextHead();
        if (isOutOfBounds(head) || isSnake(head)) {
            setGameOver(true);
            setGameStatus(false);
        } else {
            const newSnake = [head, ...snake.slice(0, -1)];
            setSnake(newSnake);
            if (head.row === food.row && head.col === food.col) {
                setFood(randomFood());
                setSnake([...newSnake, snake[snake.length - 1]]);
            }
        }
    }

    function getNextHead() {
        const { row, col } = snake[0];
        switch (direction) {
            case 'up': return { row: row - 1, col };
            case 'down': return { row: row + 1, col };
            case 'left': return { row, col: col - 1 };
            case 'right': return { row, col: col + 1 };
            default: return { row: row, col: col };
        }
    }

    function isOutOfBounds({ row, col }) {
        return row < 0 || row >= ROWS || col < 0 || col >= COLS;
    }

    function isSnake({ row, col }) {
        return snake.some(segment => segment.row === row && segment.col === col);
    }

    function handleKeyDown(event) {
        switch (event.keyCode) {
            case 90: // z
                setDirection('up');
                break;
            case 81: // q
                setDirection('left');
                break;
            case 83: // s
                setDirection('down');
                break;
            case 68: // d
                setDirection('right');
                break;
            case 38: // up arrow
                setDirection('up');
                break;
            case 40: // down arrow
                setDirection('down');
                break;
            case 37: // left arrow
                setDirection('left');
                break;
            case 39: // right arrow
                setDirection('right');
                break;
            default:
                break;
        }
    }

    function randomFood() {
        return {
            row: Math.floor(Math.random() * ROWS),
            col: Math.floor(Math.random() * COLS),
        };
    }

    function genCol(row) {
        return Array.from({ length: COLS }).map((_, col) => {
            const isSnakeSegment = isSnake({ row, col });
            const isFood = food.row === row && food.col === col;
            return (
                <div key={`${row},${col}`} className={`${row % 2 - col % 2 ? 'cell cell1' : 'cell cell2'} ${isSnakeSegment ? 'snake' : ''} ${isFood ? 'food' : ''}`} />
            );
        });
    }

    function genGrid() {
        return Array.from({ length: ROWS }).map((_, row) =>
            <div key={row} className='row'>
                {genCol(row)}
            </div>
        );
    }

    return (
        <div className='Snake' onKeyDown={handleKeyDown} tabIndex='0'>
            <h1>{gameOver ? 'Game Over' : ''}</h1>
            <button className='reset' onClick={reset}>Reset</button>
            <div className='grid' onClick={() => setGameStatus(true)}>
                {genGrid()}
            </div>
        </div>
    );
}

export default Snake;
