import React from 'react';
// import ReactDOM from 'react-dom/client';
import './TicTacToe.css';

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }
    return null;
}

function isSquareWinning(squares, index) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return index === a || index === b || index === c;
        }
    }
    return false;
}

function allFilled(squares) {
    for (let i = 0; i < squares.length; i++) {
        if (!squares[i])
            return false;
    }
    return true;
}

function Square(props) {
    const cName = props.win ? "square red" : "square";

    return (
        <button className={cName} onClick={() => props.onClick()}>
            {props.value}
        </button>
    );
}

class Board extends React.Component {
    renderSquare(i) {
        return (<Square
            win={isSquareWinning(this.props.squares, i)}
            value={this.props.squares[i]}
            onClick={() => this.props.onClick(i)} />
        );
    }

    render() {
        return (
            <div>
                <div className="board-row">
                    {this.renderSquare(0)}
                    {this.renderSquare(1)}
                    {this.renderSquare(2)}
                </div>
                <div className="board-row">
                    {this.renderSquare(3)}
                    {this.renderSquare(4)}
                    {this.renderSquare(5)}
                </div>
                <div className="board-row">
                    {this.renderSquare(6)}
                    {this.renderSquare(7)}
                    {this.renderSquare(8)}
                </div>
            </div>
        );
    }
}

class TicTacToe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [{
                squares: Array(9).fill(null),
            }],
            next: true,
            stepNbr: 0,
        }
    }

    handleClick(i) {
        const history = this.state.history.slice(0, this.state.stepNbr + 1);
        const current = history[history.length - 1];
        const squares = current.squares.slice();
        if (calculateWinner(squares) || squares[i]) {
            return;
        }
        squares[i] = this.state.next ? 'X' : 'O';
        this.setState({
            history: history.concat([{
                squares: squares,
            }]),
            stepNbr: history.length,
            next: !this.state.next,
        });
    }

    jumpTo(step) {
        this.setState({
            stepNbr: step,
            next: (step % 2) === 0,
        });
    }

    render() {
        const history = this.state.history;
        const current = history[this.state.stepNbr];
        const winner = calculateWinner(current.squares);
        let status = "";
        if (winner) {
            status = 'Winner: ' + winner;
        } else if (allFilled(current.squares)) {
            status = 'No one wins';
        } else {
            status = 'Next player: ' + (this.state.next ? 'X' : 'O');
        }

        const moves = history.map((_, move) => {
            const desc = move ? 'Go back to move #' + move : 'Reset game';
            const cName = move === this.state.stepNbr ? 'selected action-button' : 'action-button';
            return (
                <li key={move}>
                    <button className={cName} onClick={() => this.jumpTo(move)}>{desc}</button>
                </li>
            );
        });

        return (
            <div className="TicTacToe">
                <div className="game-board">
                    <Board squares={current.squares} onClick={(i) => this.handleClick(i)} />
                </div>
                <div className="game-info">
                    <div className={status.startsWith('Winner') ? 'status status--active' : 'status status--inactive'}>{status}</div>
                    <ol>{moves}</ol>
                </div>
            </div>
        );
    }
}

export default TicTacToe;

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<TicTacToe />);
