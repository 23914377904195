import en from './translations/en.json';
import fr from './translations/fr.json';

export function translate(str) {
    const oui = localStorage.getItem("lang");

    console.log(str);
    console.log(fr[str]);

    if (oui === "fr")
        return fr[str];
    else if (oui === "en")
        return en[str];
    else
        return "";
}
