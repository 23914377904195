import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navigation from './NavBar';
import Snake from './games/Snake';
import TicTacToe from './games/TicTacToe';
import GiftComponent from './Gift';
import './App.css';
import { translate } from './utils';

function Home() {
    return (
        <div>
            <h1>{translate("Home")}</h1>
        </div>
    );
}

function NotFound() {
    return (
        <div>
            <h1>404</h1>
            <h2>¯\_(ツ)_/¯</h2>
        </div>
    );
}

function App() {
    const pathname = window.location.pathname;

    return (
        <BrowserRouter>
            <div className='App'>
                {pathname !== '/etienne' && <Navigation />}
                {pathname !== '/etienne' && <br />}
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/tictactoe' element={<TicTacToe />} />
                    <Route path='/snake' element={<Snake />} />
                    <Route path='/etienne' element={<GiftComponent />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
