import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { translate } from './utils';

import './NavBar.css';

function TargetBlankIcon() {
    return (
        <svg x="0px" y="0px" viewBox="0 10 100 100" width="15" height="15" className="css-1yyhkcy">
        <path fill="currentColor" d="M18.8,85.1h56l0,0c2.2,0,4-1.8,4-4v-32h-8v28h-48v-48h28v-8h-32l0,0c-2.2,0-4,1.8-4,4v56C14.8,83.3,16.6,85.1,18.8,85.1z"></path>
        <polygon fill="currentColor" points="45.7,48.7 51.3,54.3 77.2,28.5 77.2,37.2 85.2,37.2 85.2,14.9 62.8,14.9 62.8,22.9 71.5,22.9"></polygon></svg>
    );
}

function LanguageIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
        </svg>
    );
}

function Navigation() {
    return (
        <Navbar bg='light' expand='lg' className='navbar'>
            <Navbar.Brand href='/' className='ml-auto brand-name'>mazettt.fr</Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' className='toggle' />
            <Navbar.Collapse id='basic-navbar-nav' className='collapse'>
                <Nav className='mr-auto'>
                    <NavDropdown title={translate('Games')} id='basic-nav-dropdown'>
                        <NavDropdown.Item  href='/snake'>{translate('Snake')}</NavDropdown.Item>
                        <NavDropdown.Item href='/tictactoe'>{translate('TicTacToe')}</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title='Test1' id='basic-nav-dropdown'>
                        <NavDropdown.Item href='/test'>Test</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item>
                        <Nav.Link href='https://ut.mazettt.fr/' target='_blank'>Uptime <TargetBlankIcon /></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href='https://github.com/Mazettt' target='_blank'>GitHub <TargetBlankIcon /></Nav.Link>
                    </Nav.Item>
                </Nav>
                <Nav className='ms-auto'>
                    <NavDropdown className='ms-auto lang-menu' drop='start' title={LanguageIcon()} id='basic-nav-dropdown'>
                        <NavDropdown.Item onClick={() => {localStorage.setItem('lang', 'en');window.location.reload(true)}}>English</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {localStorage.setItem('lang', 'fr');window.location.reload(true)}}>Français</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;
