import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import Confetti from 'react-dom-confetti';
import kartImg from './imgs/kart.png';
import kdoImg from './imgs/kdo.png';

const GiftComponent = () => {
    const [tremblement, setTremblement] = useState(false);
    const [isShaking, setIsShaking] = useState(false);
    const [isConfettiVisible, setIsConfettiVisible] = useState(false);
    const [giftOpened, setGiftOpened] = useState(false);
    const shakeAnimationTime = 2000;

    useEffect(() => {
        document.title = 'Joyeux anniversaire !';
    }, []);

    useEffect(() => {
        if (isShaking) {
            setTimeout(() => {
                setTremblement(!tremblement);
            }, shakeAnimationTime / 50);
        }
    }, [isShaking, tremblement]);

    const shakeAnimation = useSpring({
        transform: `scale(${isShaking ? 1.5 : 1}) rotate(${isShaking ? (tremblement ? -30 : 30) : 0}deg)`,
        config: { duration: shakeAnimationTime / 10 },
    });

    const confettiConfig = {
        angle: 90, // Réglez l'angle à 90 degrés pour que les confettis descendent
        spread: 40, // Angle de dispersion des confettis
        startVelocity: 100, // Vitesse initiale des confettis
        elementCount: 1000, // Nombre de confettis
        dragFriction: 0.1, // Friction de traînée des confettis
        duration: 10000, // Durée de l'animation
        stagger: 1, // Espacement entre les confettis
        width: '10px', // Largeur des confettis
        height: '10px', // Hauteur des confettis
        colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'], // Couleurs des confettis
    };

    const handleGiftClick = () => {
        setIsShaking(true);

        setTimeout(() => {
            setIsShaking(false);
            setGiftOpened(true);
            setIsConfettiVisible(true);
        }, shakeAnimationTime);
        setTimeout(() => {
            setIsConfettiVisible(false);
        }, shakeAnimationTime + confettiConfig.duration);
    };

    return (
        <div style={styles.container}>
            {!giftOpened && (
                <>
                    <h1 style={{...styles.topText, ...styles.topTextHappyBirthday}}>Joyeux anniversaire Etienne !</h1>
                    <h3 style={{...styles.topText, ...styles.topTextPressToOpen}}>Press to open</h3>
                    <animated.img
                        src={kdoImg}
                        alt="Cadeau"
                        style={{ ...styles.giftImage, ...shakeAnimation }}
                        onClick={handleGiftClick}
                    />
                </>
            )}
            {giftOpened && (
                <>
                    <h1 style={{...styles.topText, ...styles.topTextHappyBirthday}}>Bon pour une séance de karting</h1>
                    <img
                        src={kartImg}
                        alt="Paysage"
                        style={styles.image}
                    />
                </>
            )}
            <div style={styles.confettiContainer}>
                <Confetti active={isConfettiVisible} config={confettiConfig} />
            </div>
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#111111',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    giftImage: {
        width: '60%',
        height: 'auto',
        position: 'absolute',
        top: '40%',
        // maxWidth: '60%', // Ajustez la largeur selon vos besoins
        // maxHeight: 'auto',
        zIndex: 1, // Assurez-vous que l'image du cadeau est au-dessus des confettis
    },
    confettiContainer: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        zIndex: 1000,
    },
    image: {
        maxWidth: '100%', // Pour s'assurer que l'image ne dépasse pas la largeur de son conteneur
        maxHeight: '100%', // Pour s'assurer que l'image ne dépasse pas la hauteur de son conteneur
        position: 'absolute',
        top: '40%',
        zIndex: 2,
    },
    topText: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    topTextHappyBirthday: {
        top: 100,
        "font-weight": 'bold',
        color: '#317AC1',
    },
    topTextPressToOpen: {
        top: 200,
        color: '#999999',
    },
};

export default GiftComponent;
